<template>
  <section class="race-events">
    <header class="main">
      
      <h1>
        <router-link to="/">
          <span class="co" style="">Aravaipa Running</span> <span :class="liveClass">Live</span>
        </router-link>
      </h1>
      
    </header>

    <template v-if="raceEventsDisplay.current && raceEventsDisplay.current.length">
      <h1>Live Now</h1>
      <ul>
        <li v-for="re in raceEventsDisplay.current" :key="re.id">
          <race-event-card :race-event="re" @clicked-race="clickedRace"/>
        </li>
      </ul>
      
    </template>


    <template v-if="raceEventsDisplay.nextUp">
      <h1>{{ raceEventsDisplay.nextUp.startTime?.getTime() - Date.now() < (1 * 3600 * 1000) ? 'Live Now' : 'Next Up' }}</h1>
      <race-event-card :race-event="raceEventsDisplay.nextUp" @clicked-race="clickedRace" />
    </template>
    
    <template v-if="raceEventsDisplay.upcoming && raceEventsDisplay.upcoming.length">
      <h1>Upcoming Events</h1>
      <ul>
        <li v-for="re in raceEventsDisplay.upcoming" :key="re.id">
          <race-event-card :race-event="re" @clicked-race="clickedRace" />
        </li>
      </ul>
    </template>
    
    <template v-if="raceEventsDisplay.past">
      <h1>Past Events</h1>
      <ul>
        <li v-for="re in raceEventsDisplay.past" :key="re.id">
          <race-event-card :race-event="re" @clicked-race="clickedRace" />
        </li>
        
        <li class="control">
          <loading-spinner v-if="loading" />
          <button @click="loadMoreRaceEvents">Load More</button>
        </li>
      </ul>
    </template>
  </section>
</template>

<style lang="scss">
.race-events {
  color: var(--text-color);
  margin: calc(44px + 1em) auto 0;
  max-width: 800px;
  
  .spinner {
    position: fixed !important;
  }
  
  &>h1 {
    text-align: center;
  }
  
  .race-event-card {
    margin-bottom: 2em;
    padding: 2px;
  }
}
</style>

<script>
  import RaceEventCard from '@/components/race-event-card.vue'

  export default {
    name: 'RaceEvents',
    components: { RaceEventCard },
    
    data() {
      return {
        loading: false,
        raceEventLoadOffset: 0,
      }
    },
    
    computed: {
      liveClass() {
        return {
          live: true
        }
      },
      
      raceEvents() {
        if (!this.$store.state.raceEvents)
          return [];
      
        return Object.values(this.$store.state.raceEvents).sort((a,b)=>{
          a = a.startTime || a.createdAt || -Infinity;  // createdAt may not be loaded by indexes
          b = b.startTime || b.createdAt || -Infinity;  // createdAt may not be loaded by indexes
          return b - a;
        });
      },
      
      raceEventsDisplay() {
        if (!this.raceEvents) return {}
      
        let nextUp, current=[], upcoming = [], past = [];
        let events = [this.raceEvents];
        const now = Date.now();
        
        console.debug(`Check ${this.raceEvents.length} events`);
        
        for (var i = 0; i < this.raceEvents.length; i++) {
          const e = this.raceEvents[i];
          const deltaSt = e.startTime  ? e.startTime.getTime() - now : 0;
          const deltaCt = e.cutoffTime ? e.cutoffTime.getTime() - now : 0;
          console.debug(`${i}: ${e.name} ${deltaSt} <-> ${deltaCt}`);
          
          if (deltaSt < 0 && deltaCt > 0) {
            console.debug(`-> Current: ${e.name}`)
            current.push(e);
          }
          else
          if (deltaSt > 0) {
            // Future event
            console.debug(`-> Future: ${e.name}`)
            upcoming.push(e);
          }
          else {
            // Past event
            past.push(e);
          }
          
        }

        return {
          nextUp: upcoming.pop(),
          upcoming: upcoming && upcoming.reverse(),
          current,
          past,
        }

      },
    },
      
    
    mounted() {
      console.debug("race-events: mounted and loading");
      this.loading = true;
      this.$store.dispatch('loadRaceEvents').then(() => {
        this.loading = false;
      });
    },

    methods: {
      clickedRaceEvent(re) {
        console.log("Clicked re", re);
        // this.$router.push({name: 'raceEvent', params: { raceEventSlug: re.slug }});
      },
      
      clickedRace(re, r) {
        console.log("list: clicked", re, r);
        let opts = {
          name: 'raceEvent', 
          params: { 
            raceEventSlug: re.slug, 
          },
          query: {}
        };
        
        if (r) {
          opts.params.raceId = r.id;
          opts.query.raceId = r.id;
        }

        this.$router.push(opts);
      },
      
      loadMoreRaceEvents(e) {
        console.debug(`Loading race events with offset ${this.raceEventLoadOffset + 10}`);
        e && e.preventDefault();

        // Attempt to load the next 10, incrementing our offset tracker upon success.
        this.loading = true;
        this.$store.dispatch('loadRaceEvents', { offset: this.raceEventLoadOffset + 10}).then(() => {
          this.loading = false;
          this.raceEventLoadOffset += 10;
        });
        
      }
    }
  }
</script>