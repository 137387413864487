import { reactive, watchEffect, watch } from 'vue'

function getSettings() {
  let settings = {};
  
  // Standard localStorage methods; value is default
  const sl = {
    unitPreference: 'mi',
    commenterName: '',
    // mapStyle: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y', // default satellite
    // mapStyle: 'mapbox://styles/mapbox/outdoors-v11', // topo map
    mapStyle: 'mapbox://styles/adc/ckj1rl6hc7lwu19lfkulvseiu', // studio satellite
    // mapStyle: 'mapbox://styles/adc/ckjnzhfdj3c8219n7tvrs0vs4' // studio dark topo
  }

  // Load preferences from localStorage
  Object.keys(sl).forEach(k => {
    const localValue = window.localStorage[k];
    console.debug(`settings: ${k}:`, localValue);
    if (!localValue) {
      settings[k] = sl[k]; // default value
    }
    else {
      try {
        settings[k] = JSON.parse(localValue);
      }
      catch(e) {
        // JSON parse error.
        console.warn("settings: could not parse value", localValue);
        settings[k] = sl[k];
      }
    }
  });

  settings = reactive(settings);

  // Static or custom methods of initialization
  watchEffect(() => {
    console.debug(`watchEffect: settings.wantsMiles from settings.unitPreference (${settings.unitPreference})`);
    settings.wantsMiles = settings.unitPreference == 'mi'
  });
  
  watch(settings, (oldVal, newVal) => {
    console.log(`settings changed`, arguments);
    let keys = Object.keys(sl);
    for (var i = keys.length - 1; i >= 0; i--) {
      let key = keys[i];
      // if (oldVal[key] != newVal[key]) {
        let m = JSON.stringify(settings[key]);
        console.debug(`settings: store ${key}`);
        localStorage[key] = m;
      // }
    }
  }, {deep: true} )
  

  return settings;
}

const settings = getSettings();

export default settings;