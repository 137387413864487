<template>
  <span class="dur" :class="{neg: isNegative && allowNegative}">
    <span v-if="broken.hours>0" class="hours">{{ hoursDigits }}</span>
    <span class="minutes">{{ minutesDigits }}</span>
    <span class="seconds">{{ secondsDigits }}</span>
    <span v-if="broken.ms !== undefined && showMillis" class="ms">{{ broken.ms.toString().padStart(3, '0') }}</span>
  </span>
</template>

<style lang="scss">
.dur {
  .hours:after { content: ':'; }
  .ms, .seconds { 
    &:before {
      content: ':';
    }
  }
  .ms { color: var(--text-dim1-color); font-size: 1ex;}
  
  &.neg {
    span:first-child:before {
      content: '-';
    }
  }
}
</style>

<script>
export default {
  props: {
    start: {
      type: [Number, Date],
      required: false,
      default: 0,
    },
    finish: {
      type: [Number, Date],
      required: false,
      default: 0,
    },
    allowNegative: {
      type: Boolean,
      required: false,
      default: false
    },
    showMillis: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  
  computed: {
    startTimestamp() {
      if (this.start === undefined) return 0;
      if (this.start.getTime) return this.start.getTime();
      if (typeof this.start === 'string') return parseFloat(this.start);
      if (typeof this.start === 'number') return this.start;
      return 0;
    },
    
    finishTimestamp() {
      if (this.finish === undefined) return 0;
      if (this.finish.getTime) return this.finish.getTime();
      if (typeof this.finish === 'string') return parseFloat(this.finish);
      if (typeof this.finish === 'number') return this.finish;
      return 0;
    },
    
    isNegative() {
      return this.finishTimestamp < this.startTimestamp;
    },
    
    duration() {
      if (this.isNegative) {
        return (this.allowNegative) ? this.start - this.finish : 0
      }
      else {
        return this.finish - this.start;
      }
    },
    
    broken() {
      return this.$breakDuration(this.duration);
    },
    
    hoursDigits()   { return this.broken.hours?.toString() },
    minutesDigits() { return String(this.broken.minutes||0).padStart(2, '0') },
    secondsDigits() { return String(this.broken.seconds||0).padStart(2, '0') },
  },
  
}
</script>