<template>
  <div class="race-event-card">
    <div class="bg" :style="backgroundStyle">
      <header @click="clickedRace(null)">
        <figure class="race-logo">
          <img :src="raceLogoImage" :alt="raceEvent.name + ' Logo'" />
        </figure>

        <h1>{{ raceEvent.name }}</h1>
        <h2 v-if="firstRace">
          {{ $fmt.full(firstRace.startTime, this.timezoneName) }}
        </h2>
      </header>

      <div v-if="timezoneName" class="tz-info">
        This event time is shown in <em>local time at the race start location</em><br />
        (location is {{ timezoneDifferenceDisplay }} than browser local time)
      </div>
      
      <template v-if="true">
        <ul class="race-list" :class="{group: races.length > 1}">
          <li v-for="r in races" :key="r.id" :style="`--race-color: ${r.color}`" @click="clickedRace(r)">
            <span class="rname">{{ r.name }}</span>
            <span class="rtime">{{ $fmt.full(r.startTime, this.timezoneName) }}</span>
            <span v-if="raceEvent.timezoneOffset != localTimezoneOffset" class="tz">({{ timezoneDisplay }})</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.race-event-card {
  border-radius: 16px;
  margin: 1em 6px;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
  --logo-width: 96px;
  --logo-height: max(100px, calc(64px + 8px + 8px));
  --bg: transparent;
  --overlay-bg: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0.0) 10%, rgba(0,0,0,0.4) 100%);
  --left-pad: calc(var(--logo-width) + 8px + 8px);
  position: relative;
  overflow: hidden;
  color: #fff;
  
  .bg {
    border-radius: 16px;
    
    min-height: var(--logo-height);
    position: relative;
    padding: 1em;
    cursor: pointer;
//    background: var(--bg), var(--overlay-bg);
    background:var(--bg);
  }
  
  header {
    padding-left: var(--left-pad);

  }
  
  &:hover {
    h1 {
      text-shadow: 0 0 10px rgba(255,255,255,0.5);
      color: rgba(255,255,255,1);
    }
  }
  
  &:active {
    h1 {
      transition: all 0.1s ease;
      text-shadow: 0 2px 4px rgba(255,255,255,0.5);
      color: rgba(0,0,0,0.7);
    }
  }

  canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  h1 {
    font-size: 44px;
    line-height: 44px;
    color: rgba(0,0,0,1);
    text-align: left;
    margin: 0;
    padding-top: 8px;
    padding-left: 0 - var(--left-pad);
    font-weight: 900;
//    text-shadow: 0 -4px rgba(255,255,255,1);
    text-shadow: 0 -1px rgba(0,0,0,0.5);
    color: rgba(250,250,250, 0.8);
    mix-blend-mode: hard-light;

    transition: all 0.5s ease;
  }
  
  h2 {
    font-size: 22px;
    font-weight: 100;
    line-height: 32px;
//    color: rgba(0,0,0,1);
    color: #fff;
    text-align: left;
    margin: 0;
    text-shadow: 0 1px rgba(255,255,255,0.5);
  }
  
  .race-logo {
    position: absolute;
    left: 8px;
    top: calc(1em + 0px);
    width: var(--logo-width);
    height: var(--logo-width);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.2);
    border-radius: 50%;
    
    img {
      max-width: calc(var(--logo-width) * 1.1);
      max-height: calc(var(--logo-width) * 1.1);
      filter: drop-shadow(0px 5px 10px rgba(255,255,255,0.8));
    }
  }
  
  .tz-info {
    padding-left: calc(var(--logo-width) + 1rem);
  }
  
  .race-list {
    margin-top: 0.5em; 
    li { padding: 0 0.5em; transition: all 0.4s ease; display: flex; align-items: center; }
    li:nth-child(2n+1) { background: rgba(0,0,0,0.1);}
    li:hover { text-shadow: 0 0 5px rgba(255,255,255,1); }
    
    // Race colors unless there's only one race, in which case the
    // entire card will be the correct color
    &.group li {
      border-left: 4px solid var(--race-color);
    }
    
    span { display: inline-block; }
    .rname { width: calc(var(--logo-width) + 2.75rem); }
    .tz { 
      margin-left: 1ex; 
      padding: 0.25em 0.5rem;
      background: rgba(0,0,0,0.3);
      border-radius: 4px;
      font-size: 0.75em;
      font-weight: bold;
      font-variant: small-caps;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.6);
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 375px) {
  .race-event-card {
    --logo-width: 64px;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* phones in portrait mode */
  .race-event-card {
    header {
      h2 {
        font-size: 1.15rem;
      }
    }
    .race-list {
      li {
        line-height: 2rem;
        .rtime { flex-basis: 73%;}
      }
    }
  }
}

</style>

<script>
  import Trianglify from 'trianglify'
  import workletURL from "@/workers/triangle-painter.js?worker&url"
  import aravaipaLogo from '@/assets/img/aravaipa-logo.png'
  // import { formatTimestamp }  from '@z/lib/format'
  const x = import.meta//.rolldownOptions.input['triangle-painter'];

  export default {
    props: {
      raceEvent: {
        type: Object,
        required: true,
      }
    },
    
    emits: ['clickedRace'],
    
    data() {
      return {
        backgroundType: undefined // 'paint' or 'webkit'
      }
    },
    
    computed: {
      races() { 
        return this.$store.getters.entity('races', this.raceEvent.races).sort((a,b) => b.distance - a.distance ); 
      },

      racesByStartTime() { 
        return [...this.races].sort((a,b) => {
          if (!a || !a.startTime) return -Infinity;
          if (!b || !b.startTime) return Infinity;
          return a.startTime - b.startTime; 
        });
      },

      firstRace() {
        return this.racesByStartTime && this.racesByStartTime[0];
      },
      
      raceLogoImage() {
        if (this.raceEvent.avatarUrl)
          return this.raceEvent.avatarUrl;
        
        return aravaipaLogo;
      },
      
      backgroundStyle() {
        return this.backgroundType == 'paint' ?
          `--bg: paint(triangles); --rc: ${this.raceColors.join(' ')}` :
          `--bg: -webkit-canvas(${this.raceEvent.slug})`
      },
      
      // Name that will be accepted by a tzinfo database
      timezoneName() {
        const offset = this.raceEvent.timezoneOffset;
        return offset == this.localTimezoneOffset ? undefined : `Etc/GMT+${Math.abs(offset)}`;
      },
      
      // Name that will be accepted by a participant or spectator
      timezoneDisplay() {
        const offset = this.raceEvent.timezoneOffset;
        if (offset == this.localTimezoneOffset) return undefined;
        
        return `UTC${offset}`;
      },
      
      localTimezoneOffset() {
        return new Date().getTimezoneOffset() / 60 * -1;
      },
      
      timezoneDifferenceDisplay() {
        const diff = this.localTimezoneOffset - this.raceEvent.timezoneOffset;
        const absDiff = Math.abs(diff);

        return `${absDiff} ${this.$fmt.pluralize(absDiff, 'hour')} ${diff > 0 ? 'earlier' : 'later'}`
      },
      
      raceColors() {
        let colors = (this.races.length) ? [this.races[0].color || '#1E8991'] : ['#1E8991'];
        
        if (colors.length < 1)
          colors.push('#eee');
        
        for (let i=1; i<this.races.length && i<2; i++)
          colors.push(this.races[i++].color);
        
        if (colors.length < 2) {
          // darker version of the race color
          let c = colors[0];
          let tint = (col, amt) => {
            if(col.indexOf('#')==0) col = col.substr(1);
            let colorval = parseInt(col, 16);

            let r = (colorval >> 16) & 0xff,
                g = (colorval >> 8) & 0xff,
                b = (colorval >> 0) & 0xff;
            
            r += amt; if (r > 0xff) r = 0xff;
            g += amt; if (g > 0xff) r = 0xff;
            b += amt; if (b > 0xff) r = 0xff;
            
            col = r << 16 | g << 8 | b;
            return col.toString(16);
          }

          colors.push(tint(c, 72));
        }
        
        return colors;
      }
    },
    
    mounted() {
      // card background
      if (!document.getCSSCanvasContext) {
        // console.log("Houdini!", workletURL);
        // console.log("Houdini Module: ", x);
        this.backgroundType = 'paint';
        try {
          if (!window.cssPaintWorklets) window.cssPaintWorklets = [];
          if (!window.cssPaintWorklets.includes('triangle-painter')) {
            // console.log(`adding triangle-painter module: (${workletURL})`);
            window.cssPaintWorklets.push('triangle-painter');
            CSS.paintWorklet.addModule(workletURL);
          }
        }
        catch (e) {
          // fallback
          this.$el.style.backgroundColor = 'var(--med-bgcolor)';
        }
      }
      else {
        this.backgroundType = 'webkit';
        if (this.paintBackground()) {
          document.body.addEventListener('resize', () => this.paintBackground() );
          document.body.addEventListener('orientationchange', () => this.paintBackground() );
        }
        else {
          // fallback
          this.$el.style.backgroundColor = 'var(--med-bgcolor)';
        }
      }
      
    },
    
    methods: {
      paintBackground() {
        try {
          let colors = this.raceColors;
        
          window.t = Trianglify;
        
          requestAnimationFrame(() => {
            console.debug(`paint background (scaling height ${this.$el.clientHeight}) ver2`, colors);
            var pattern = Trianglify({
              width: document.body.clientWidth,
              height: this.$el.clientHeight,
              cellSize: 30,
              xColors: colors,
              variance: 0.8,

              // y_colors: ['red', ...colors]
              // x_colors: 'RdYlGn',
            });
        
            const ctx = document.getCSSCanvasContext("2d", this.raceEvent.slug, document.body.clientWidth, this.$el.clientHeight);
            pattern.toCanvasCtx(ctx);
          })

          return true
        } catch (e) {
          console.error("Could not paint background:", e);
          return false;
        }
      },
      
      clickedRace(r) {
        console.debug("card: clicked race", r);
        this.$emit('clickedRace', this.raceEvent, r);
      },
    }
  }
</script>