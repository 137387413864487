export default function install(app) {
  app.config.globalProperties.allowBodyScroll = function(flag) {
    if (flag) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
    }
    else {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }
  }
}