import {
  createApp
} from 'vue'
import ActionCableVue from 'actioncable-vue';
import VueGtag from "vue-gtag";

import http from '@/lib/http'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import formatters from '@/lib/format'
import settings from '@/lib/settings'
import bodyscrollcontrol from '@/lib/body-scroll-control'
import duration from '@/lib/duration'


// Unregister old service workers
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (let registration of registrations) {
      console.log("Checking installed service worker:", registration);

      registration.addEventListener("updatefound", () => {
        // If updatefound is fired, it means that there's
        // a new service worker being installed.
        console.log(`Updating serviceWorker`, registration);
      });

      // ['/welcome', '/trackside', '/race_events.ics', '/overlay'].forEach(path => {
      //   console.debug("Checking path", window.location.pathname.startsWith(path));
      //   if (window.location.pathname.startsWith(path)) {
      //     console.warn("Removing registration")
          registration.unregister();
      //   }
      // });
    }
  });
}


const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: (process.env.NODE_ENV == 'development' && !window.location.search.startsWith('?live')) ?
    `ws://${window.location.hostname}:3000/ws` : 'wss://timing.aravaiparunning.com/ws',
  connectImmediately: true
};

window.apiBaseUrl = (function() {
  let host;
  if (process.env.NODE_ENV == 'development') {
    if (window.location.search.startsWith('?live')) {
      host = "https://live.aravaiparunning.com"
    } else {
      host = location.protocol + '//' + location.hostname;
      host += ':3000' // front end dev server is on a different port
    }
  } else {
    host = location.protocol + '//' + location.host;
  }
  return host + '/api/v1/';
})()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(http, {
    baseURL: apiBaseUrl,
    csrfHeader: true,
    headers: {
      'Accept': 'application/json',
      'x-live-ver': '200',
    },
    authorization: () => `bearer ${localStorage.parToken}`
  })
  .use(duration)
  .use(bodyscrollcontrol)
  .use(VueGtag, {
    appName: 'Momentum Live',
    pageTrackerScreenviewEnabled: true,
    config: {
      id: 'G-5QMQX906P0',
      params: {
        send_page_view: true
      }
    }
  }, router);

// .use(ActionCableVue, actionCableVueOptions)

app.config.globalProperties.$fmt = {
  ...formatters
};
app.config.globalProperties.settings = settings;
app.config.unwrapInjectedRef = true;

import LoadingSpinner from '@/components/loading-spinner.vue'
app.component('LoadingSpinner', LoadingSpinner);
import DurationDisplay from '@/components/duration-display.vue'
app.component('DurationDisplay', DurationDisplay);

app.mount('#app');
