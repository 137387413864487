export default function* stateWalkRelations(schema, state, entitySchema, id) {
  // We need to find and remove all ids that reference this object
  // The object's relations are in its schema object
  const undeadRelKeySingular = entitySchema.key;
  const undeadRelKeyPlural = schema.pluralize(undeadRelKeySingular);
  const undead = state[undeadRelKeyPlural][id];

  // ID will be a string because of javascript
  // This line prevents references from being duplicated.
  //
  id = parseInt(id);

  // console.debug(`walk ${undeadRelKeySingular} #${id}`);
  // console.debug(`State: walking relations of ${undeadRelKeySingular}[${id}]`);

  if (!undead) 
    return console.error("Cannot find entity to destroy with ID", id);
    
  // We have e.g. an eventRoute that is pending deletion (undead). 
  // Split <->> EventRoute <<-> EventSource
  // We need all relations that reflect it (one Split and one EventSource) 
  // and their keys (split.*eventRoutes*, eventSource.*eventRoute*)
  // In a one-to-one relationship, the key would be singular.
  //
  // console.debug('looping keys', Object.keys(entitySchema.schema || []));
  for (const relation of Object.keys(entitySchema.schema || [])) {
//    console.debug(`************** New relation ${relation} **************`);
    // e.g. {split: EntitySchema}
    // Remove all ID references in this relation's `entites` array
    // If the relation is to-many, we will use a pluralized key.
    // E.g.:
    //   split schema: {
    //     race: EntitySchema,
    //     splits: [EntitySchema]
    //   }
    //
    let relationEntity = entitySchema.schema[relation];
    const relationIsToMany = Array.isArray(relationEntity);
    if (relationIsToMany) relationEntity = relationEntity[0];
  
    // Find this relation entity's relation that reflects our schema.
    // i.e. Find this split's eventRoute:EntitySchema or eventRoutes:[EntitySchema] relationship
    // so that we can know if we're nulling or splicing out our eventRoute's ID
    //
    // relationEntity will be e.g. splits
    // reflectionIsToMany refers to the reflection back to undead
    const {relationKey, isToMany} = schema.reflect(entitySchema, relationEntity);
    const reflectionIsToMany = isToMany;
  
//    console.log(`Got relationKey '${relationKey}'; reflectionIsToMany: ${reflectionIsToMany}`);
    if (!relationKey) 
      return console.warn("Could not find relkey for %s relation %s", entitySchema._key, relation);
  
    // Now we can find the relation object's entry for undead
    // so we can splice it out.
    // e.g. split.eventRoutes == [1,3,4,...];
    //
    // Find the id of the object that references the undead
    // const relId = undead[]
    //
    // @TODO: move this to a vuex getter 
    // If the `relationIsToMany`, we need to find each relation that belongs_to undead.
    // 
    if (relationIsToMany) {
      // e.g. while deleting a participant, each of their crossings needs
      // to be visited. Yield an object that allows access to each crossing.
      //
      const relationIdKey = relationKey + 'Id'; // => e.g. raceId
//      console.debug(`-> ✅ root has_many ${relation}, each with key ${relationIdKey} (should null each related object.relationId)`);

      const r = {
        name: relation, 
        type: 'hasMany',
        attribute: relationIdKey,
      };
      

      // console.debug('relation: ', r);
      yield r;
    }
    // Otherwise, if the relation is to-one, we just need to find that one.
    else {
      // E.g. removing a race when race ->> splits
      // We have a Split here. 
      // We need to mutate the split to no longer refer to the race.
      //
      const stateKey = schema.pluralize(relation);
      const relationId = undead[relation + 'Id']; // undeadEventRoute.splitId


      if (undefined === state[stateKey]) {
        console.warn(`state.${stateKey} is missing. Skipping.`);
        continue;
      }
      else if (!state[stateKey]) {
        state[stateKey] = {};
      }
        
      
      if (!relationId) {
        // This object (undead) does not reference any $(relation)s so there's 
        // nothing for us to do here.
        // console.debug(`${undeadRelKeySingular}.${relation}Id is null`);
        continue;
      }
      
      const relationObject = state[stateKey][relationId];

//      console.debug("=> Got relation object state.%s[%d]", stateKey, relationId, relationObject);
    
      if (reflectionIsToMany) {
        // The relationship is many-to-many
        // relationObject has lots of `entity`s, so we splice
        //
        // const relatedObjectId = undead.id;
        // const i = relationObject[relationKey].indexOf(relatedObjectId);
        // console.debug("-> Splice out index", i);
        //relationObject[relationKey].splice(i, 1);
//        console.debug(`-> ✅ reflection has_many ${relationKey}: ${stateKey}[${relationId}].${relationKey} (should splice)`);
        const r = {
          name: stateKey, 
          type: 'belongsTo', 
          owner: relationObject, 
          key: relationKey, 
          objectId: relationId
        };
//        console.log('relation: ', r);
        yield r;
      }
      else {
        // e.g. an EventRoute belongs to a Split; The Split has_many EventRoutes.
        // We have the Split here, and we need to 
        // relationObject has one `entity`, so we null it.
        // relationObject[relationKey] = undefined;
        console.debug(`-> ✅ root belongs_to (the only ${stateKey}[${relationId}].${relationKey}) (relationObject[relationKey] = undefined)`);
        
        // debugger;
        
        const r = {
          name: stateKey, 
          type: 'hasOne',
          relatedObject: relationObject,
          key: relationKey
        };
        
        console.log('relation: ', r);
        yield r;
      }
    }
  }
}
