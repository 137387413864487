import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";

import RaceEvents from '@/views/race-events.vue'

const routes = [
  {
    path: '/',
    name: 'race-events',
    component: RaceEvents
  },
  
  {
    path: '/:raceEventSlug',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (race-event.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "race-event" */ '@/views/race-event.vue'),
    children: [
      {
        name: 'raceEvent',
        path: '',
        component: () => import(/* webpackChunkName: "race-event" */ '@/views/race-event-home.vue'),
        // props: true,
      },
      {
        name: 'raceInfo',
        path: 'race/:raceId',
        component: () => import(/* webpackChunkName: "race-event" */ '@/views/race-info.vue'),
        props: true,
      },
      {
        path: ':participantId',
        name: 'participant',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (participant.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "participant" */ '@/views/participant.vue'),
      },
      {
        path: ':participantId/comments',
        name: 'comments',
        props: true,
        component: () => import(/* webpackChunkName: "participant" */ '@/views/comments.vue'),
      },
    ]
  },
  
];

const router = createRouter({
  history: createWebHistory(),     // doesn't work with subdirectories, yet.
  history: createWebHashHistory(),
  routes,
});

export default router;