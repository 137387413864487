import { createStore } from 'vuex'
import { readonly } from 'vue'

import {normalize} from 'normalizr'

import schema from '@/schema'
import storeEntityGetters from '@/lib/store/store-entity-getters'
import storeEntityActions from '@/lib/store/store-entity-actions'
import storeEntityMethods from '@/lib/store/store-entity-methods'

const store = createStore({
  state() {
    return {
      schema: readonly(schema),
      
      loading: [], // urls that are loading
      loadingEntities: [],

      // websocket subscriptions
      connections: [],

      raceEvents: null,
      races: null,
      splits: null,
      participants: null,
      crossings: {},
    }
  },
  
  getters: {
    ...storeEntityGetters
  },

  mutations: {
    
    ...storeEntityMethods,
    
    /* Websocket tracking */
    channelSubcriptions(state, {channel, connected}) {
      const i = state.connections.indexOf(channel);

      if (connected) {
        console.debug("store: connected to", channel);
        (i < 0) && state.connections.push(channel);
      }
      else {
        console.debug(`store: disconnected from ${channel}, index ${i}`);
        if (i < 0) return;
        state.connections.splice(i, 1);
      }
    },
  },
  
  actions: {
    ...storeEntityActions,
    
    loadRaceEvents(context, payload) {
      let requestingSlug = payload?.requestingSlug; // ES6 destructuring doesn't support defaults?
      let offset = payload?.offset;
      
      if (context.state.loadingRaceEventsPromise) {
        console.debug("loadingRaceEventsPromise is ongoing", context.state.loadingRaceEventsPromise);
        return;
      }
      context.state.loadingRaceEventsPromise = new Promise((complete) => {
        console.debug(`store: loadRaceEvents (requestingSlug:'${requestingSlug}')`);
        let url = 'race_events/live';
        let qs = new URLSearchParams();
        if (requestingSlug) qs.append('slug', requestingSlug);
        if (offset) qs.append('o', offset);
        if (qs.toString()) url += '?' + qs.toString();
          
        return this.axios.get(url).then(response => {
          // Normalize entities
          const data = normalize(response.data, [schema.raceEvent]);
      
          // Generate slugs
          Object.values(data.entities.raceEvents).forEach(e => {
            if (!e.slug) {
              e.slug = e.name.toLowerCase().replace(/[^\w\d]/g, '-');
            }
          });

          //  Send to state
          this.commit('commitEntities', data);
          complete();
        });
      }).then(() => context.state.loadingRaceEventsPromise = null);
      
      return context.state.loadingRaceEventsPromise;
    },
    
    
  },
  
});

export default store;