const secPerDay = 24 * 60 *60;
const secPerHour = 60 * 60;
const secPerMin = 60;

export function breakDuration(ms=0, opts={}) {
  if (ms === null || ms === undefined || isNaN(ms)) return {invalid: true};
  if (ms === Infinity || ms === -Infinity) return {infinity: true};
  
  let res = {};
  let t = ms / 1000.0;

  if (opts.breakDays) {
    res.days = Math.floor(t / secPerDay);
    if (res.days)
      t -= (res.days * secPerDay);
  }

  res.hours = Math.floor(t / secPerHour);
  if (res.hours)
    t -= (res.hours * secPerHour);

  res.minutes = Math.floor(t / secPerMin);
  if (res.minutes)
    t -= (res.minutes * secPerMin);

  res.ms = t * 1000.0;
  res.seconds = Math.floor(t);
  res.ms = Math.floor(res.ms - (res.seconds * 1000));

  return res;
}

export default function install(app) {
  
  app.config.globalProperties.$breakDuration = breakDuration;

}