export default {
  entity: state => (entityName, ids) => {
    if (!entityName) return console.warn('entity: no entityName given');
    if (!ids) return [];

    let single = false;

    if (typeof ids == "string") {
      ids = parseInt(ids);
      single = true;
    }
    
    if (typeof ids == 'number')  {
      single = true;
      ids = [ids]
    }
    
    if (undefined === state[entityName]) {
      console.warn(`entity: state.${entityName} is not defined.`);
      debugger;
      return single ? undefined : [];
    }
    
    
    let results = [];
    for (let i = ids.length - 1; i >= 0; i--) {
      let obj = state[entityName][ids[i]];
      if (obj) results.push(obj)
    }
    
    //console.debug('getter.entity: results for ids', ids, results);
    
    return single ? 
      results.length ?
        results[0] :
        /*undefined*/ console.warn('entity: single undefined') :
      results;
  },
}