import Axios from 'axios'

export default function install(app, options={}) {

  // Craete axios instance with global config
  //
  const axios = Axios.create(options)

  axios.interceptors.request.use(function(config) {
    config.headers['Cache-Control'] = 'max-age=60';
    return config;
  });

  // Create an interceptor to set the csrf token
  //
  if (options['csrfHeader']) {
    // Send authenticity token with each request.
    axios.interceptors.request.use(function(config) {
      const mv = document.querySelector('meta[name=csrf-token]');
      const t = mv ? mv.getAttribute('content') : '';
      config.headers['x-csrf-token'] = t;
      return config
    });
  }
  
  if (options['basePath']) {
    axios.interceptors.request.use(function(config) {
      console.warn('http: basePath unimplemented; consider using baseURL');
      return config;
    });
  }
  
  if (options['authorization']) {
    axios.interceptors.request.use(function(config) {
      config.headers['Authorization'] = options.authorization();
      return config;
    });
  }

  app.config.globalProperties.axios = axios;

  // Install in store if present.
  let store;
  if ((store = app.config.globalProperties.$store)) {
    store.axios = axios;
  }
  
  return app;
}