export const ISO8601RegEx = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i

export function dateFromISOString(str) {
  // Is it a string?
  if (typeof str !== "string") return null;

  // Does it look like a date?
  const m = str.match(ISO8601RegEx);
  if (!m || !m[1]) return null;

  // Does it round trip correctly?
  try {
    const d = new Date(str);
    
    // Time zone differences make this check fail
    // if (d.toISOString() != str) return null;
    
    // It's a date.
    return d;
  }
  catch(e) {
    return null;
  }
}

export function reviveJSONDates(obj) {
  Object.keys(obj).forEach((k) => {
    
    const v = obj[k];
    const d = dateFromISOString(v);

    // Replace the key's value with a date.
    if (d)
      obj[k] = d;
  })
  

  return obj;
}