import {readonly} from 'vue'
import {reviveJSONDates} from '@/lib/store/store-utils'
import stateWalkRelations from '@/lib/store/state-walk-relations'

/* Entity Loading Management */
// Args:
//   + entityName: a string referencing an entity that is loading
//   + isLoading:  a boolean value indicating if that thing is loading or not.
//
function setLoadingStatus(state, {entityName, isLoading}) {
  if (isLoading) {
    if (state.loadingEntities.indexOf(entityName) < 0)
      state.loadingEntities.push(entityName);
  }
  else {
    let i;
    while ((i=state.loadingEntities.indexOf(entityName)) >= 0) {
      state.loadingEntities.splice(i,1);
    }
    console.debug(`=> Finished loading ${entityName}`, );
  }
}

/* Entity committing */
// Args:
//   + payload.entities must be a hash of entity names in normalized form
//
//   + payload.parent should be an object whos entityName key will be set
//
//     when the load is finished. This is needed to give a destination for
//     empty results sets. 
//
//   + payload.onResolve can be a hook that gets called when this is 
//     done committing all entities.
//
//   + payload.pruneIf can be a closure that will remove entities that meet
//     a certain criteria. E.g., forget crossings older than 30 minutes.
//
export function commitEntities(state, payload) {
  
  console.log("commitEntities: ", payload);
  const schema = state.schema;
  const promiseResolve = payload.onResolve; // may be undefined
  const parent = payload.parent;            // may be undefined
  const pruneIf = payload.pruneIf;          // may be undefined

  if (payload.entities)
    payload = payload.entities;
  
  Object.keys(payload).forEach((entityName) => {
    const entitySchema = schema[schema.singularize(entityName)];

    // Add the key if it didn't already exist, making it reactive.
    if (!state[entityName]) {
      //console.warn(`Adding state.${entityName}`);
      state[entityName] = {};
    }
  
    // Insert all entities into the store
    // This needs to be done in such a way as to be reactive.
    // We're essentially doing Object.assign(state[entityName], payload[entityName]),
    // but calling Vue.set along the way.
    // 
    Object.keys(payload[entityName]).forEach((id) => {
      let obj = payload[entityName][id];
      
      reviveJSONDates(obj);
      
      // standardize on integer IDs where they're not ephemeral strings
      // @TODO: Should we actually standardize on just expecting string IDs?
      if (id.indexOf && id.indexOf('NEW') < 0)
        id = parseInt(id);
      
      // If the object already exists in the store, update individual keys
      // instead of obliterating the whole object.
      // Also try to find an ephemeral-keyed object to update with a new ID
      // instead of duplicating it on the round-trip back.
      //
      let stateObj = undefined;
      if (obj.ephemeralKey && (stateObj = state[entityName][obj.ephemeralKey])) {
        //console.debug(`result ephemeralKey: ${obj.ephemeralKey}; storeObj:`, state[entityName][obj.ephemeralKey]);
        // We have state.myThings.NEW-32452352 = {...}
        // - We need to move that object to state.myThings[obj.id]
        // - And update all relations.
        stateObj = Object.assign({id}, stateObj);
        state[entityName][id] = stateObj;
        
        // @TODO: I *think* we can rip out the code from destroyEntity and pass a
        // closure to it that will walk the relations and either splice (for destroy) 
        // or change ID (for this)
        //
        // The below does kind of that by deleting the ephemeral object that was successfully
        // created and then letting the code below update (poorly) its relations
        delete state[entityName][obj.ephemeralKey];
        // we have no context here context.commit('destroyEntity', {
        //   entitySchema: schema[schema.singularize(entityName)],
        //   id: obj.ephemeralKey
        // });
        
      }
      else {
        stateObj = state[entityName][id];
      }
      
      const readonlyAttrs = entitySchema.readonly || [];
      
      if (stateObj) {
        Object.keys(obj).forEach(key => {
          // console.debug("store: update key for stateObj",stateObj, key, obj[key]);
          stateObj[key] = readonlyAttrs.includes(key) ? readonly(obj[key]) : obj[key];
        });
      }
      else {
        // Safe to quickly insert the whole object
        readonlyAttrs.forEach(k => {
          if (obj.hasOwnProperty(k)) {
            console.debug(`💥 making key ${k} readonly`);
            let val = obj[k];
            delete obj[k];
            obj[k] = readonly(val);
          }
        })
        stateObj = state[entityName][id] = obj;
      }
      
      // Update each related object to point to this object
      // e.g. if this is a participant, add it to `races[this.raceId].participants`
      //
//          console.groupCollapsed(`Walking relations of ${schema.singularize(entityName)}[${id}]`);
      for (const relation of stateWalkRelations(schema, state, entitySchema, id)) {
        switch (relation.type) {
          case 'belongsTo': {
            if (!relation.owner) { 
              // The object doesn't exist yet. Stub it with the relation so that it
              // will have something to merge with later.
              let stub = {id: relation.objectId};
              stub[relation.key] = [stateObj.id];
              // console.log(`=> Creating state.${relation.name}[${relation.objectId}] with id: ${relation.objectId}`);

              if (relation.objectId !== undefined)
                state[relation.name][relation.objectId] = stub;
            }
            else {
              // The object exists and it holds an array of references to 
              // stateObj-like objects
              //
              if (!relation.owner[relation.key]) {
                // console.warn(`=> Creating ${relation.name}[${relation.owner.id}].${relation.key}[${id}]`);
                relation.owner[relation.key] = [id];
              }
              else
              if (relation.owner[relation.key].indexOf(id) < 0) {
                // push stateObj into `relation.name`'s `relation.key`
                // e.g. 
                //     {name: "raceEvents", type: "belongsTo", owner: {…}, key: "races", objectId: 2833}
                // 
                if (typeof(id) == 'string') {
                  // This happens when the ID is e.g. NEW-123456.
                  // I don't know what we want to do differently here.
                  //
                  
                  //relation.owner[relation.key].push(id);
                }

                if (!relation.owner[relation.key])
                  relation.owner[relation.key] =[];  // theRaceEvent.races ||= []

                // console.log(`=> pushing ${relation.name}[${relation.owner.id}].${relation.key} << ${id} (${typeof(id)})`);
                relation.owner[relation.key].push(id);
              }
            }
            break;
          }
        
          case 'hasMany': {
            // console.debug(`hasMany relation on ${entitySchema._key}: `, relation);
            // console.debug(`hasMany: ${entitySchema._key}.${relation.name}`, stateObj[relation.name]);
            if (stateObj[relation.name]) {
              for (const relatedObjectId of stateObj[relation.name]) {
                // Update this relatedObject's reflected relation attribute to point at stateObj
                if (!state[relation.name])
                  break;
                
                let relatedObject = state[relation.name][relatedObjectId];

                if (!relatedObject) {
                  // Create a stub.
                  relatedObject = state[relation.name][relatedObjectId] = {
                    id: relatedObjectId,
                  }
                }

                relatedObject[relation.attribute] = id;
              }
            }
           break;
          }
        
          case 'hasOne': {
            console.warn(`Unimplemented hasOne relation on ${entitySchema.key}: `, relation);
            // debugger;
            break;
          }
        }
      }
//          console.groupEnd();
      
    }); // each id in payload
  
    // Update relations if available.
    if (parent && Object.values(payload[entityName]).length < 1) {
      // No entity relationships will be updated for this result, which will
      // cause the request to be resent in an infinite loop. We break this loop
      // by correctly setting the relation key on `payload.parent` to an empty array.
      if (!parent[entityName]) {
        // e.g. race.participants = []
        parent[entityName] = payload[entityName];
      }
    }
    
    // Prune objects that meet a certain criteria
    //
    if (pruneIf) {
      for (const obj of Object.keys(payload[entityName])) {
        console.warn('Unimplemented: prune obj', obj);
      }
    }
  });//each key in payload
  
  if (promiseResolve)
    promiseResolve();
}

// Rudely destroys an entity without any discussion
//
function deleteEntity(state, {entityName, id}) {
  delete state[entityName][id];  
}

export default {
  setLoadingStatus,
  commitEntities,
  deleteEntity,
}